const DocumentFields = [
    {
        key: 'name',
        label: 'Name',
        sortable: true,
    },
    {
        key: 'code',
        label: 'Code',
        sortable: true,
    },
    {
        key: 'is_active',
        label: 'Status',
        sortable: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: 'response_time',
        label: 'R. Time (Days)',
        sortable: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: 'cost',
        label: 'Min. Cost',
        sortable: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: 'discount_percent',
        label: '% Discount',
        sortable: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: 'is_end_of_process',
        label: 'End of Process',
        sortable: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: 'templates',
        label: 'Templates',
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: 'created_by',
        label: 'Created By',
        sortable: false,
    },
    {
        key: "action",
        thClass: "text-center",
        tdClass: "text-center",
    }
];

export default {
    DocumentFields,
};