<template>
  <b-modal
    v-model="showModal"
    ref="modal-documents"
    title="View Template"
    title-tag="h3"
    modal-class="modal-primary"
    no-close-on-backdrop
    hide-footer
    @hidden="() => $emit('close')"
  >
    <MessageConfigTab :template="template" v-if="template" :isModal="true"/>
  </b-modal>
</template>

<script>
import DocumentService from "@/views/debt-solution/views/settings/views/documents-config/documents/service/document.service.js";
import MessageConfigTab from "@/views/debt-solution/views/settings/views/message-cost-config/components/MessageConfigTab.vue";
import { mapGetters } from "vuex";

export default {
  name: "ViewTemplateModal",
  components: {
    MessageConfigTab,
  },
  props: {
    templateSelected: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showModal: true,
      template: null,
    };
  },
  async mounted() {
    await this.getDocumentById();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async getDocumentById() {
      try {
        const data = await DocumentService.getMessageConfigByIdCi({
          id: this.templateSelected.template_id,
        });
        this.template = data;
        this.template.type = this.template.type == "EMAIL" ? 1 : 2;
        this.template.templateSelected = this.templateSelected;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style scoped>
.select__error {
  border: 1px solid #f1556c !important;
  border-radius: 5px;
}
</style>
