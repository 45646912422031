var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.documentId ? 'EDIT DOCUMENT' : 'CREATE NEW DOCUMENT',"title-tag":"h3","modal-class":"modal-primary","no-close-on-backdrop":""},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button-cancel',{on:{"click":function($event){return cancel()}}}),_c('button-save',{on:{"click":function($event){return _vm.save()}}})]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":_vm.isAnswer ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Name:"}},[_c('validation-provider',{attrs:{"rules":"required|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"maxlength":"255","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(errors[0])?_c('span',{staticClass:"text-danger mt-1"},[_vm._v("Name "+_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":_vm.isAnswer ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Code:"}},[_c('validation-provider',{attrs:{"rules":"min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"maxlength":"255","state":errors.length > 0 ? false : null},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),(errors[0])?_c('span',{staticClass:"text-danger mt-1"},[_vm._v("Code "+_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":_vm.isAnswer ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Service Type:"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors[0] ? 'select__error' : '',attrs:{"multiple":"","label":"name","placeholder":"Select Service Type","options":_vm.G_DOCUMENT_SERVICE_TYPE_ACTIVES,"reduce":function (option) { return option.id; },"state":errors.length > 0 ? false : null,"disabled":_vm.documentId == null},on:{"option:deselected":_vm.handleDeselect},model:{value:(_vm.form.service_type),callback:function ($$v) {_vm.$set(_vm.form, "service_type", $$v)},expression:"form.service_type"}}),(errors[0])?_c('span',{staticClass:"text-danger mt-1"},[_vm._v("Service Type "+_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),(_vm.isAnswer)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Response Time (days):"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"state":errors.length > 0 ? false : null,"min":"1","max":"99","type":"text"},model:{value:(_vm.form.response_time),callback:function ($$v) {_vm.$set(_vm.form, "response_time", $$v)},expression:"form.response_time"}}),(errors[0])?_c('span',{staticClass:"text-danger mt-1"},[_vm._v("Response Time "+_vm._s(errors[0]))]):_vm._e()]}}],null,false,3119317691)})],1)],1):_vm._e(),(_vm.isAnswer)?_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',[_c('b-form-group',{attrs:{"id":"min-cost-input","label":"Min. Cost","label-for":"min-cost-input"}},[_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.form.min_cost),callback:function ($$v) {_vm.$set(_vm.form, "min_cost", $$v)},expression:"form.min_cost"}},'money',_vm.vMoney,false))],1)],1)],1):_vm._e(),(_vm.isAnswer)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"% Discount"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.##'),expression:"'##.##'"}],attrs:{"state":errors.length > 0 ? false : null,"min":"1","max":"99","type":"text"},model:{value:(_vm.form.discount_percent),callback:function ($$v) {_vm.$set(_vm.form, "discount_percent", $$v)},expression:"form.discount_percent"}})]}}],null,false,2996284595)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Description:"}},[_c('validation-provider',[_c('b-form-textarea',{attrs:{"maxlength":"255"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"switch":""},model:{value:(_vm.form.is_end_of_process),callback:function ($$v) {_vm.$set(_vm.form, "is_end_of_process", $$v)},expression:"form.is_end_of_process"}},[_vm._v("Is End Of Process ")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('This document finalizes the process'),expression:"'This document finalizes the process'",modifiers:{"hover":true,"right":true}}],staticClass:"clickable ml-1 mt-1 text-primary",attrs:{"icon":"InfoIcon","size":"17"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }