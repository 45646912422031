<template>
  <b-modal
    v-model="show"
    :title="documentId ? 'EDIT DOCUMENT' : 'CREATE NEW DOCUMENT'"
    title-tag="h3"
    modal-class="modal-primary"
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-row>
        <b-col :cols="isAnswer ? 6 : 4">
          <b-form-group label="Name:">
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:1|max:255"
            >
              <b-form-input
                v-model="form.name"
                maxlength="255"
                :state="errors.length > 0 ? false : null"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Name {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col :cols="isAnswer ? 6 : 4">
          <b-form-group label="Code:">
            <validation-provider v-slot="{ errors }" rules="min:1|max:255">
              <b-form-input
                v-model="form.code"
                maxlength="255"
                :state="errors.length > 0 ? false : null"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Code {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col :cols="isAnswer ? 6 : 4">
          <b-form-group label="Service Type:">
            <validation-provider v-slot="{ errors }" rules="required">
              <v-select
                multiple
                label="name"
                placeholder="Select Service Type"
                v-model="form.service_type"
                :options="G_DOCUMENT_SERVICE_TYPE_ACTIVES"
                :reduce="(option) => option.id"
                :state="errors.length > 0 ? false : null"
                :class="errors[0] ? 'select__error' : ''"
                @option:deselected="handleDeselect"
                :disabled="documentId == null"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Service Type {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6" v-if="isAnswer">
          <!-- Response time to change the case to investigation -->
          <b-form-group label="Response Time (days):">
            <validation-provider v-slot="{ errors }">
              <b-form-input
                v-model="form.response_time"
                :state="errors.length > 0 ? false : null"
                v-mask="'##'"
                min="1"
                max="99"
                type="text"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Response Time {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6" v-if="isAnswer">
          <validation-provider>
            <b-form-group
              id="min-cost-input"
              label="Min. Cost"
              label-for="min-cost-input"
            >
              <money
                v-bind="vMoney"
                v-model="form.min_cost"
                class="form-control"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6" v-if="isAnswer">
          <b-form-group label="% Discount">
            <validation-provider v-slot="{ errors }">
              <b-form-input
                v-model="form.discount_percent"
                :state="errors.length > 0 ? false : null"
                v-mask="'##.##'"
                min="1"
                max="99"
                type="text"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Description:">
            <validation-provider>
              <b-form-textarea v-model="form.description" maxlength="255" />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <div class="d-flex">
            <b-form-checkbox
              switch
              v-model="form.is_end_of_process"
              class="mt-1"
              >Is End Of Process
            </b-form-checkbox>
            <feather-icon
              icon="InfoIcon"
              class="clickable ml-1 mt-1 text-primary"
              size="17"
              v-b-tooltip.hover.right="'This document finalizes the process'"
            />
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save @click="save()" />
    </template>
  </b-modal>
</template>

<script>
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import DocumentService from "@/views/debt-solution/views/settings/views/documents-config/documents/service/document.service.js";
import { mapGetters, mapActions } from "vuex";
import VMoney from "v-money";

export default {
  components: {
    buttonSave,
    buttonCancel,
    VMoney,
  },
  props: {
    documentId: {
      type: Number,
      default: null,
    },
    tab: {
      type: Number,
      requried: true,
    },
  },
  data() {
    return {
      form: {
        document_id: null,
        name: "",
        description: "",
        currentUser: null,
        status: 1,
        is_end_of_process: null,
        service_type: [],
        service_type_removed: [],
        code: "",
        response_time: null,
        min_cost: 0,
        discount_percent: 0,
      },
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      show: true,
    };
  },
  async mounted() {
    await this.A_GET_DOCUMENT_SERVICE_TYPE_ACTIVES();

    this.form.currentUser = this.currentUser.user_id;

    if (this.documentId) {
      this.getDocumentById();
      this.form.document_id = this.documentId;
    }
    this.form.service_type = [this.tab];
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_DOCUMENT_SERVICE_TYPE_ACTIVES:
        "DSCourtInfoDocumentServiceTypeSettingsStore/G_DOCUMENT_SERVICE_TYPE_ACTIVES",
    }),

    isAnswer() {
      return this.tab === 1;
    },
  },
  methods: {
    ...mapActions({
      A_GET_DOCUMENT_SERVICE_TYPE_ACTIVES:
        "DSCourtInfoDocumentServiceTypeSettingsStore/A_GET_DOCUMENT_SERVICE_TYPE_ACTIVES",
    }),

    close() {
      this.$emit("close");
    },

    async save() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          await DocumentService.createOrUpdateDocument(this.form);
          this.showSuccessSwal("Document saved successfully");
          this.close();
          this.$emit("closing");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getDocumentById() {
      try {
        const data = await DocumentService.getDocumentsById({
          id: this.documentId,
        });
        this.form.name = data[0].name;
        this.form.description = data[0].description;
        this.form.is_end_of_process = data[0].is_end_of_process ? true : false;
        this.form.service_type = JSON.parse(data[0].service_type);
        this.form.status = data[0].is_active;
        this.form.code = data[0].code;
        this.form.response_time = data[0].response_time;
        this.form.min_cost = data[0].cost ?? 0;
        this.form.discount_percent = data[0].discount_percent;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    handleDeselect($event) {
      this.form.service_type_removed.push($event.id);
    },
  },
};
</script>

<style scoped>
.select__error {
  border: 1px solid #f1556c !important;
  border-radius: 5px;
}
</style>
