export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Service Type",
    placeholder: "Select Service Type",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    placeholder: "Select Status",
    model: null,
    options: [
      { value: 1, label: "Active" },
      { value: 0, label: "Inactive" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "End of Process",
    placeholder: "Select End of Process",
    model: null,
    options: [
        {
            label: "YES",
            value: 1,
          },
          {
            label: "NO",
            value: 0,
          },
    ],
    reduce: "value",
    selectText: "label",
    cols: 6,
  },
];
