<template>
  <div class="px-2">
    <filter-slot
      :filter="FilterSlot.filter"
      :totalRows="FilterSlot.totalRows"
      :paginate="FilterSlot.paginate"
      :startPage="FilterSlot.startPage"
      :toPage="FilterSlot.toPage"
      :filterPrincipal="FilterSlot.filterPrincipal"
      @reload="$refs['documentsTable'].refresh()"
    >
      <template #buttons>
        <div class="ml-2">
          <b-button variant="primary" @click="openModalCreateDocument()">
            <feather-icon icon="PlusIcon" size="15" class="mr-50" />Create
          </b-button>
        </div>
      </template>
      <b-table
        ref="documentsTable"
        slot="table"
        class="blue-scrollbar"
        primary-key="id"
        empty-text="No matching records found"
        responsive="md"
        sticky-header="50vh"
        small
        show-empty
        no-provider-filtering
        :busy.sync="isBusy"
        :fields="visibleFields"
        :items="myProvider"
        :per-page="FilterSlot.paginate.perPage"
        :current-page="FilterSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(name)="data">
          <span>{{ data.item.name }}</span>
          <feather-icon
            v-if="data.item.description"
            icon="InfoIcon"
            class="clickable ml-1 text-primary"
            size="17"
            v-b-tooltip.hover.right="data.item.description"
          />
        </template>

        <template #cell(is_active)="data">
          <b-badge
            :variant="data.item.is_active ? 'light-success' : 'light-danger'"
          >
            {{ data.item.is_active ? "ACTIVE" : "INACTIVE" }}
          </b-badge>
        </template>
        <template #cell(discount_percent)="data">
          <span
            class="text-primary"
            v-if="data.item.discount_percent && data.item.discount_percent > 0"
          >
            % {{ data.item.discount_percent }}
          </span>
          <span v-else> - </span>
        </template>
        <template #cell(cost)="data">
          <span
            class="text-success"
            v-if="data.item.cost && data.item.cost > 0"
          >
            $ {{ data.item.cost }}
          </span>
          <span v-else> - </span>
        </template>
        <template #cell(response_time)="data">
          <span
            class="text-primary"
            v-if="data.item.response_time && data.item.response_time > 0"
          >
            {{ data.item.response_time }}
          </span>
          <span v-else> - </span>
        </template>
        <template #cell(is_end_of_process)="data">
          <b-badge
            :variant="
              data.item.is_end_of_process ? 'light-primary' : 'light-secondary'
            "
          >
            {{ data.item.is_end_of_process ? "YES" : "NO" }}
          </b-badge>
        </template>
        <template #cell(created_by)="data">
          <span>{{ data.item.created_by }}</span> <br />
          <span>{{ data.item.created_at }}</span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              :icon="data.item.is_active ? 'SlashIcon' : 'CircleIcon'"
              class="clickable mr-1"
              :class="data.item.is_active ? 'text-danger' : 'text-success'"
              size="17"
              @click="changeStatus(data.item)"
              v-b-tooltip.hover.left="
                data.item.is_active ? 'INACTIVE' : 'ACTIVE'
              "
            />

            <feather-icon
              icon="EditIcon"
              class="cursor-pointer mr-1 text-info"
              size="17"
              @click="openModalEditDocument(data.item)"
            />

            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              size="17"
              @click="deleteDocument(data.item)"
            />
          </div>
        </template>
        <template #cell(templates)="data">
          <template v-if="data.item.templates">
            <b-row v-if="JSON.parse(data.item.templates)[0].title != null">
              <template
                v-for="(template, index) in JSON.parse(data.item.templates)"
              >
                <b-col
                  cols="auto"
                  class="cursor-pointer"
                  :key="index"
                  @click="openTemplateModal(template)"
                >
                  <b-badge
                    :variant="
                      template.type == 'SMS' ? 'light-success' : 'light-primary'
                    "
                    class="mr-1"
                  >
                    <feather-icon
                      :icon="template.type == 'SMS' ? 'PhoneIcon' : 'MailIcon'"
                      size="15"
                      v-b-tooltip.hover.right="
                        template.type == 'SMS' ? 'SMS' : 'EMAIL'
                      "
                      class="text-center"
                    />
                  </b-badge>
                </b-col>
              </template>
            </b-row>
          </template>
        </template>
      </b-table>
    </filter-slot>

    <modal-documents
      v-if="openModalDocument"
      :documentId="documentId"
      @close="openModalDocument = false"
      @closing="$refs['documentsTable'].refresh()"
      :tab="tab"
    />

    <ViewTemplateModal
      v-if="showTemplateModal"
      :templateSelected="templateSelected"
      @close="showTemplateModal = false"
    />
  </div>
</template>

<script>
import Fields from "@/views/debt-solution/views/settings/views/documents-config/documents/data/fields.data.js";
import filter from "@/views/debt-solution/views/settings/views/documents-config/documents/data/filters.data.js";
import modalDocuments from "@/views/debt-solution/views/settings/views/documents-config/documents/components/modal/ModalDocuments.vue";
import DocumentService from "@/views/debt-solution/views/settings/views/documents-config/documents/service/document.service.js";
import ViewTemplateModal from "@/views/debt-solution/views/settings/views/documents-config/documents/components/modal/ViewTemplateModal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    modalDocuments,
    ViewTemplateModal,
  },
  data() {
    return {
      openModalDocument: false,
      documentId: null,
      Fields,
      isBusy: false,
      FilterSlot: {
        filter,
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: 0,
        toPage: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
        templateId: null,
      },
      showTemplateModal: false,
      templateSelected: {},
    };
  },
  async mounted() {
    await this.A_GET_DOCUMENT_SERVICE_TYPE_ACTIVES();
    this.FilterSlot.filter[2].options = this.G_DOCUMENT_SERVICE_TYPE_ACTIVES;
  },
  computed: {
    ...mapGetters({
      G_DOCUMENT_SERVICE_TYPE_ACTIVES:
        "DSCourtInfoDocumentServiceTypeSettingsStore/G_DOCUMENT_SERVICE_TYPE_ACTIVES",
    }),
    tab() {
      return this.$route.matched[5].meta.tab;
    },

    visibleFields() {
      if (this.$route.matched[5].meta.tab == 1) {
        let labels = [
          "name",
          "code",
          "is_active",
          "discount_percent",
          "cost",
          "response_time",
          "is_end_of_process",
          "templates",
          "created_by",
          "action",
        ];

        this.Fields.DocumentFields.forEach((item) => {
          if (labels.includes(item.key)) {
            item.visible = true;
          } else {
            item.visible = false;
          }
        });
      } else {
        let labels = [
          "name",
          "code",
          "is_active",
          "created_by",
          "action",
          "is_end_of_process",
        ];

        this.Fields.DocumentFields.forEach((item) => {
          if (labels.includes(item.key)) {
            item.visible = true;
          } else {
            item.visible = false;
          }
        });
      }
      return this.Fields.DocumentFields.filter((field) => field.visible);
    },
  },
  methods: {
    ...mapActions({
      A_GET_DOCUMENT_SERVICE_TYPE_ACTIVES:
        "DSCourtInfoDocumentServiceTypeSettingsStore/A_GET_DOCUMENT_SERVICE_TYPE_ACTIVES",
    }),

    openModalCreateDocument() {
      this.openModalDocument = true;
      this.documentId = null;
    },

    openModalEditDocument(item) {
      this.openModalDocument = true;
      this.documentId = item.id;
    },

    async myProvider(ctx) {
      try {
        this.addPreloader();

        let order = ctx.sortDesc ? "desc" : "asc";
        let orderBy = ctx.sortBy ? ctx.sortBy : "id";
        order = ctx.sortBy ? order : "desc";

        const data = await DocumentService.getDocuments({
          serach_text: this.FilterSlot.filterPrincipal.model,
          page: this.FilterSlot.paginate.currentPage,
          perPage: this.FilterSlot.paginate.perPage,
          order_by: orderBy,
          order: order,
          date_from: this.FilterSlot.filter[0].model,
          date_to: this.FilterSlot.filter[1].model,
          service_type_id: this.FilterSlot.filter[2].model,
          status: this.FilterSlot.filter[3].model,
          end_of_process: this.FilterSlot.filter[4].model,
          tab: this.tab,
        });

        this.FilterSlot.totalRows = data.total;
        this.FilterSlot.startPage = data.from ? data.from : 0;
        this.FilterSlot.toPage = data.to ? data.to : 0;
        this.FilterSlot.paginate.currentPage = data.current_page;
        this.FilterSlot.paginate.perPage = data.per_page;
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async changeStatus(item) {
      try {
        const confirm = await this.showConfirmSwal(
          `Are you sure you want to ${
            item.is_active ? "inactive" : "active"
          } this item?`
        );
        if (confirm.isConfirmed) {
          const data = await DocumentService.createOrUpdateDocument({
            document_id: item.id,
            name: item.name,
            description: item.description,
            status: item.is_active ? 0 : 1,
            is_end_of_process: item.is_end_of_process,
            service_type: [],
            currentUser: null,
            service_type_removed: [],
            code: item.code,
            response_time: item.response_time,
            min_cost: item.cost,
            discount_percent: item.discount_percent,
          });
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Document status change successfully"
          );
          this.$refs["documentsTable"].refresh();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async deleteDocument(item) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          await DocumentService.deleteDocument({
            id: item.id,
          });
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Document deleted successfully"
          );
          this.$refs["documentsTable"].refresh();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    getBadgeColor(type, index) {
      const availableColors = [
        "light-info",
        "light-success",
        "light-danger",
        "light-secondary",
      ];
      switch (type.toUpperCase()) {
        case "INVESTIGATION":
          return "light-primary";
        case "ANSWER":
          return "light-warning";
        default:
          return availableColors[index % 2];
      }
    },

    openTemplateModal(templateSelected) {
      this.showTemplateModal = true;
      this.templateSelected = templateSelected;
    },
  },
};
</script>

<style scoped>
</style>
